import React from 'react';

import './index.scss';


export const ColoredButton = ({ 
    children, onClick, type='primary', size='regular' 
}) => {

    return <button 
        type='button'
        className={`btn btn-${type} ${size === 'large' && 'btn-large'}`}
        onClick = {onClick}
    >
        {children}
    </button>
}

export default ColoredButton;