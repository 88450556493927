import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';

import './index.scss';
import { CourseCard } from '../../components/CourseCard';
import { postLog } from '../../services';
import course_1 from '../../assets/course_1.png';
import course_2 from '../../assets/course_2.png';
import course_3 from '../../assets/videoconference.jpg';


const scrollToTargetDiv = (id) => {
    const targetDiv = document.getElementById(id);

    if (targetDiv) {
        targetDiv.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }
};

export const CoursesSection = () => {

    const navigate = useNavigate();

    return <div className='courses-section text-center pb-5'>
        <h1>
            <Trans 
                i18nKey="coursesPage.coursesSection.title"
            />
        </h1>
        <h4 className='mt-5 courses-section-subtitle'>
            <Trans 
                i18nKey="coursesPage.coursesSection.subtitle"
                components={{
                    red: <span className='red' />,
                    yellow: <span className='yellow' />,
                }}
            />
        </h4>
        <div className='row courses-card-container'>
            <div className='col-lg-4 col-sm-12'>
                <CourseCard 
                    image={course_1}
                    i18nKey='coursesPage.coursesSection.privateClassesTitle'
                    handleLearnMoreClick={
                        () => {
                            postLog('click', 'button', 'learn_more_private_classes');
                            scrollToTargetDiv('private-classes-box');
                        }
                    }
                    handleBookNowClick={
                        () => {
                            postLog('click', 'button', 'book_now_private_classes');
                            //navigate('/prices?scrollTo=adults-children-classes');
                            window.open('/booking?classType=freeTrial', '_self');
                        }
                    }
                />
            </div>
            <div className='col-lg-4 col-sm-12 mt-5 mt-lg-0'>
                <CourseCard 
                    image={course_2}
                    i18nKey='coursesPage.coursesSection.conversationClassesTitle'
                    handleLearnMoreClick={
                        () => {
                            postLog('click', 'button', 'learn_more_conversation_classes');
                            scrollToTargetDiv('conversation-classes-box')
                        }
                    }
                    handleBookNowClick={
                        () => {
                            postLog('click', 'button', 'book_now_conversation_classes')
                            //navigate('/prices?scrollTo=conversation-classes')
                            window.open('/booking?classType=freeTrial', '_self');
                        }
                    }
                />
            </div>
            <div className='col-lg-4 col-sm-12 mt-5 mt-lg-0'>
                <CourseCard 
                    image={course_3}
                    i18nKey='coursesPage.coursesSection.groupClassesTitle'
                    handleLearnMoreClick={
                        () => {
                            postLog('click', 'button', 'learn_more_group_classes');
                            scrollToTargetDiv('group-classes-box')
                        }
                    }
                    handleBookNowClick={
                        () => {
                            postLog('click', 'button', 'book_now_group_classes');
                            //navigate('/prices?scrollTo=adults-children-classes')
                            window.open('/booking?classType=freeTrial', '_self');
                        }
                    }
                />
            </div>
        </div>
    </div>
}

export default CoursesSection;