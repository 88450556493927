import React, { useState, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { Trans, useTranslation } from 'react-i18next';

import './index.scss';
import { postEmail, postLog } from '../../services';
import variables from '../../variables.scss';


export const ContactUsSection = () => {

    const { t } = useTranslation();

    const initialFormData = {
        name: '',
        emailAddress: '',
        message: ''
    }

    const initialValidationErrors = {
        name: false,
        emailAddress: false,
        message: false
    }

    const [ formData, setFormData ] = useState(initialFormData);
    const [ validationErrors, setValidationErrors ] = useState(initialValidationErrors);
    const [ emailSending, setEmailSending ] = useState(false);
    const [ confirmationDisplaying, setConfirmationDisplaying ] = useState(false);


    const ConfirmationMessage = ({ children }) => {
        const [isVisible, setIsVisible] = useState(true);
      
        useEffect(() => {
            const timeout = setTimeout(() => {
                setIsVisible(false);
                setConfirmationDisplaying(false);
            }, 5000);
    
            return () => clearTimeout(timeout);
        }, []);
      
        return <>
            {isVisible && <div className='confirmation-message'>
                {children}
            </div>}
        </>
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }))
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setConfirmationDisplaying(false);
        setEmailSending(true);
        let validationError = false;
        // reset any error that may have been thwrown on last submit
        setValidationErrors(initialValidationErrors);
    
        // validate form: fields can't be null
        Object.keys(formData).forEach(
            (key) => {
                if (formData[key] === '') {
                    setValidationErrors((prevState) => ({
                        ...prevState,
                        [key]: true
                    }));
                    validationError = true;
                }
            }
        )

        if (!validationError) {
            postEmail(
                formData['name'], formData['emailAddress'], formData['message']
            )
            setConfirmationDisplaying(true);
            // reset form
            setFormData(initialFormData);
            postLog('send_message', 'outcome', 'success');
        } else {
            postLog('send_message', 'outcome', 'error');
        }
        setEmailSending(false);
    }

    const CSSOverride = {
        display: 'block',
        margin: '15% auto',
        zIndex: '100',
        position: 'absolute',
        top: '50%',
        left: '45%'
    };

    return <div className='position-relative'>
        <ClipLoader 
            loading={emailSending}
            size={100}
            cssOverride={CSSOverride}
            color={variables['button-color-primary']}
        />
        <div 
            className={
                `contact-us-section text-center ${emailSending && 'blur-and-disable'}`
            }
            id='contact-form'
        >
            <h1>
                <Trans
                    i18nKey='contactsPage.contactsSection.title'
                />
            </h1>
            <h4 className='mt-5'>
                <Trans
                    i18nKey='contactsPage.contactsSection.subtitle.firstParagraph'
                />
            </h4>
            <h4 className='mt-4'>
                <Trans
                    i18nKey='contactsPage.contactsSection.subtitle.secondParagraph'
                />
            </h4>
            <div className='contact-form-container text-start'>
                <form onSubmit={handleFormSubmit}>
                    <div className='form-group'>
                        <div className='row'>
                            <div className='col-lg-3 col-md-4'>
                                <label>
                                    <Trans
                                        i18nKey='contactsPage.contactsSection.form.name.description'
                                    />
                                </label>
                            </div>
                            { validationErrors['name'] && 
                            <div className='col-lg-9 col-md-8 error-message'>
                                <Trans
                                    i18nKey='contactsPage.contactsSection.form.name.errorMessage'
                                />
                            </div> }
                        </div>
                        <input 
                            type='name'
                            name='name' 
                            className={
                                `form-control mt-3 ${validationErrors['name'] && 
                                'validation-error'}`
                            }
                            placeholder={
                                t('contactsPage.contactsSection.form.name.placeholder')
                            }
                            value={formData.name}
                            onChange={handleChange}
                        >
                        </input>
                    </div>
                    <div className='form-group mt-4'>
                        <div className='row'>
                            <div className='col-lg-3 col-md-4'>
                                <label>
                                    <Trans
                                        i18nKey='contactsPage.contactsSection.form.emailAddress.description'
                                    />
                                </label>
                            </div>
                            { validationErrors['emailAddress'] && 
                                <div className='col-lg-9 col-md-8 error-message'>
                                    <Trans
                                        i18nKey='contactsPage.contactsSection.form.emailAddress.errorMessage'
                                    />
                                </div> }
                        </div>
                        <input 
                            type='email' 
                            name='emailAddress'
                            className={
                                `form-control mt-3 ${validationErrors['emailAddress'] && 
                                'validation-error'}`
                            }                        
                            placeholder={
                                t('contactsPage.contactsSection.form.emailAddress.placeholder')
                            }
                            value={formData.emailAddress}
                            onChange={handleChange}
                        >
                        </input>
                    </div>
                    <div className='form-group mt-4'>
                        <div className='row'>
                            <div className='col-lg-3 col-md-4'>
                                <label>
                                    <Trans
                                        i18nKey='contactsPage.contactsSection.form.message.description'
                                    />
                                </label>
                            </div>
                            { validationErrors['message'] && 
                            <div className='col-lg-9 col-md-8 error-message'>
                                <Trans
                                    i18nKey='contactsPage.contactsSection.form.emailAddress.errorMessage'
                                />
                            </div> }
                        </div>
                        <textarea 
                            type='text'
                            name='message'
                            rows='8' 
                            className={
                                `form-control mt-3 ${validationErrors['message'] && 
                                'validation-error'}`
                            }                        
                            placeholder={
                                t('contactsPage.contactsSection.form.message.placeholder')
                            }
                            value={formData.message}
                            onChange={handleChange}
                        >    
                        </textarea>
                    </div>
                    <div className='row mb-5 mb-lg-0'>
                        <div className='col-4'>
                            <button type='submit' className='btn btn-primary mt-5'>
                                <Trans
                                    i18nKey='contactsPage.contactsSection.form.sendMessageButton'
                                />
                            </button>
                        </div>
                        <div className='col-8'>
                            {confirmationDisplaying &&
                            <div className='mt-4'>
                                <ConfirmationMessage>
                                    <Trans
                                        i18nKey='contactsPage.contactsSection.form.confirmationMessage'
                                    />
                                </ConfirmationMessage>
                            </div>}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
}

export default ContactUsSection;