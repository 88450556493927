import React from 'react';
//import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';

import './index.scss';
import { PricingCard } from '../../components/PricingCard';
import { BoxWithCTAButton } from '../../components/BoxWithCTAButton';
import { postLog } from '../../services';


export const PricesSection = () => {

    return <div className='prices-section text-center pb-5'>
        <div className='price-card-group-container' id='adults-children-classes'>
            <h4 className='mb-5'>
                <Trans 
                    i18nKey='pricesPage.pricesSection.intro'
                />
            </h4>
            <BoxWithCTAButton
                i18nKey='pricesPage.pricesSection.freeTrialCTAButton'
                handleCTAButtonClick={
                    () => {
                        postLog('click', 'regular_classes_price_button', 'free_trial');
                        window.open('/booking?classType=freeTrial', '_self');
                    }
                }
            >
                <h3>
                    <Trans
                        i18nKey='pricesPage.pricesSection.adultChildrenBox.title'
                    />
                </h3>
                <div className='row mt-5'>
                    <div 
                        className='col-lg-4 col-sm-12 d-flex 
                        justify-content-center'
                    >
                        <PricingCard 
                            titleI18nKey='pricesPage.pricesSection.adultChildrenBox.oneSessionCard.title'
                            priceI18nKey='pricesPage.pricesSection.adultChildrenBox.oneSessionCard.price'
                            detailI18nKey='pricesPage.pricesSection.adultChildrenBox.oneSessionCard.detail'
                            handleBookNowClick={() => {
                                postLog('click', 'regular_classes_price_button', '1_class');
                                window.open('/booking', '_self');
                            }}
                        />
                    </div>
                    <div 
                        className='col-lg-4 col-md-12 d-flex 
                        justify-content-center mt-5 mt-lg-0'
                    >
                        <PricingCard 
                            titleI18nKey='pricesPage.pricesSection.adultChildrenBox.tenSessionsCard.title'
                            priceI18nKey='pricesPage.pricesSection.adultChildrenBox.tenSessionsCard.price'
                            detailI18nKey='pricesPage.pricesSection.adultChildrenBox.tenSessionsCard.detail'
                            handleBookNowClick={() => {
                                postLog('click', 'regular_classes_price_button', '10_classes');
                                window.open('/booking', '_self');
                            }}
                        />
                    </div>
                    <div 
                        className='col-lg-4 col-md-12 d-flex 
                        justify-content-center mt-5 mt-lg-0'
                    >
                        <PricingCard 
                            titleI18nKey='pricesPage.pricesSection.adultChildrenBox.twentySessionsCard.title'
                            priceI18nKey='pricesPage.pricesSection.adultChildrenBox.twentySessionsCard.price'
                            detailI18nKey='pricesPage.pricesSection.adultChildrenBox.twentySessionsCard.detail'
                            handleBookNowClick={() => {
                                postLog('click', 'regular_classes_price_button', '20_classes');
                                window.open('/booking', '_self');
                            }}
                        />
                    </div>
                </div>
            </BoxWithCTAButton>
        </div>

        <div className='price-card-group-container mb-5 pb-5' id='conversation-classes'>
            <BoxWithCTAButton
                i18nKey='pricesPage.pricesSection.freeTrialCTAButton'
                handleCTAButtonClick={
                    () => {
                        postLog('click', 'conversation_classes_price_button', 'free_trial');
                        window.open('/booking?classType=freeTrial', '_self');
                    }
                }
            >
                <h3>
                    <Trans
                        i18nKey='pricesPage.pricesSection.conversationBox.title'
                    />
                </h3>
                <div className='row mt-5'>
                    <div 
                        className='col-lg-4 col-md-12 d-flex 
                        justify-content-center'
                    >
                        <PricingCard 
                            titleI18nKey='pricesPage.pricesSection.conversationBox.oneSessionCard.title'
                            priceI18nKey='pricesPage.pricesSection.conversationBox.oneSessionCard.price'
                            detailI18nKey='pricesPage.pricesSection.conversationBox.oneSessionCard.detail'
                            handleBookNowClick={() => {
                                postLog('click', 'conversation_classes_price_button', '1_class');
                                window.open('/booking', '_self');
                            }}
                        />
                    </div>
                    <div 
                        className='col-lg-4 col-md-12 d-flex 
                        justify-content-center mt-5 mt-lg-0'
                    >
                        <PricingCard 
                            titleI18nKey='pricesPage.pricesSection.conversationBox.tenSessionsCard.title'
                            priceI18nKey='pricesPage.pricesSection.conversationBox.tenSessionsCard.price'
                            detailI18nKey='pricesPage.pricesSection.conversationBox.tenSessionsCard.detail'
                            handleBookNowClick={() => {
                                postLog('click', 'conversation_classes_price_button', '10_classes');
                                window.open('/booking', '_self');
                            }}
                        />
                    </div>
                    <div 
                        className='col-lg-4 col-md-12 d-flex 
                        justify-content-center mt-5 mt-lg-0'
                    >
                        <PricingCard 
                            titleI18nKey='pricesPage.pricesSection.conversationBox.twentySessionsCard.title'
                            priceI18nKey='pricesPage.pricesSection.conversationBox.twentySessionsCard.price'
                            detailI18nKey='pricesPage.pricesSection.conversationBox.twentySessionsCard.detail'
                            handleBookNowClick={() => {
                                postLog('click', 'conversation_classes_price_button', '20_classes');
                                window.open('/booking', '_self');
                            }}
                        />
                    </div>
                </div>
            </BoxWithCTAButton>
        </div>

        <div className='price-card-group-container mb-5' id='group-classes'>
            <BoxWithCTAButton
                i18nKey='pricesPage.pricesSection.freeTrialCTAButton'
                handleCTAButtonClick={
                    () => {
                        postLog('click', 'group_classes_price_button', 'free_trial');
                        window.open('/booking?classType=freeTrial', '_self');
                    }
                }
            >
                <h3>
                    <Trans
                        i18nKey='pricesPage.pricesSection.groupBox.title'
                    />
                </h3>
                <div className='row mt-5 pb-4 pb-md-0'>
                    <div className='col-lg-4 desktop-only'></div>
                    <div 
                        className='col-lg-4 col-md-12 d-flex 
                        justify-content-center'
                    >
                        <PricingCard 
                            titleI18nKey='pricesPage.pricesSection.groupBox.tenSessionsCard.title'
                            priceI18nKey='pricesPage.pricesSection.groupBox.tenSessionsCard.price'
                            detailI18nKey='pricesPage.pricesSection.groupBox.tenSessionsCard.detail'
                            handleBookNowClick={() => {
                                postLog('click', 'group_classes_price_button', '10_classes');
                                window.open('/booking', '_self');
                            }}
                        />
                    </div>
                    <div className='col-lg-4 desktop-only'></div>
                </div>
            </BoxWithCTAButton>
        </div>

    </div>
}

export default PricesSection;