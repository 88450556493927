import { axios } from './index';


export const userLogin = async ( email, password, remember=false ) => {    
    // Login user

    // REQUIRES AUTHENTICATION

    const data = { 
        'email': email,
        'password': password,
        'remember': remember 
    }
    const response = await axios.post(`auth/login`, data, { withCredentials: true });

    return response.data;
}

export const userSignup = async ( email, password, firstName, lastName ) => {
    // create a new user

    const data = {
        'email': email,
        'password': password,
        'first_name': firstName,
        'last_name': lastName
    }
    const response = await axios.post(`auth/signup`, data);

    return response.data;
}

export const verifyAuth = async () => {
    // check if user is authenticated; if an error is raised, that
    // means the token is missing, invalid or expired; errors are 
    // not handled at this level
    
    // REQUIRES AUTHENTICATION

    const response = await axios.get(`auth/verify`, { withCredentials: true });

    return response.data;
}

export const refreshToken = async () => {
    // refresh JWT token

    // REQUIRES AUTHENTICATION

    const response = await axios.post(`auth/refresh_token`, {}, { withCredentials: true });

    return response.data;
}

export const userLogout = async () => {
    // logout user

    // REQUIRES AUTHENTICATION

    // the request's second argument must be the JSON body, even if this is
    // empty. Not including an empty request body in the axios request will 
    // result in the { withCredentials: true } part being considered as the 
    // request body, and thus being disregarded
    const response = await axios.post(`auth/logout`, {}, { withCredentials: true });

    return response.data;
}