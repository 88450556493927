import React from 'react';

import { LoginForm } from '../../components/LoginForm';
import './index.scss';


export const LoginPage = () => {
    return <div className='login-page'>
        <LoginForm />
    </div>
}

export default LoginPage;