import React from 'react';
import { Trans } from 'react-i18next';

import './index.scss';
import why_us_image from '../../assets/why_us_image.jpg';


export const WhyUsSection = () => {
    return <div>
        <div className='why-us-section-inner-container'>
            <h1 className='text-center'>
                <Trans 
                    i18nKey="coursesPage.whyUsSection.title"
                />
            </h1>
            <div className='row mt-5 flex-column-reverse flex-md-row'>
                <div className='col-lg-8 col-md-12 pe-lg-5'>
                    <ul>
                        <li>
                            <Trans 
                                i18nKey="coursesPage.whyUsSection.firstBullet"
                            />
                        </li>
                        <li>
                            <Trans 
                                i18nKey="coursesPage.whyUsSection.secondBullet"
                                components={{
                                    italic: <i></i>
                                }}
                            />
                        </li>
                        <li>
                            <Trans 
                                i18nKey="coursesPage.whyUsSection.thirdBullet"
                            />
                        </li>
                        <li>
                            <Trans 
                                i18nKey="coursesPage.whyUsSection.fourthBullet"
                            />
                        </li>
                    </ul>
                </div>
                <div className='col-lg-4 col-md-12 mb-5 mb-lg-0 text-center'>
                    <img 
                        src={why_us_image} 
                        alt='corsi di Spagnolo' 
                        width='80%'
                    />
                </div>
            </div>
        </div>
    </div>
}

export default WhyUsSection;