import React from 'react';
import { Trans } from 'react-i18next';

import './index.scss';
import videoconference from '../../assets/videoconference.jpg';


export const PricesForCompaniesSection = () => {
    return <div className='prices-for-companies-section text-center'>
        <div className='row'>
            <div className='col-12 col-md-8'>
                <img 
                    src={videoconference} 
                    alt='Spanish classes for companies' 
                    width='100%'
                />
                <div className='features-box-with-image text-start'>
                    <h2>
                        <Trans 
                            i18nKey="companiesPage.courseDescriptionBox.title"
                        />
                    </h2>
                    <div className='mt-4'>
                        <p>
                            <Trans 
                                i18nKey="companiesPage.courseDescriptionBox.firstParagraph"
                            />
                        </p>
                        <p>
                            <Trans 
                                i18nKey="companiesPage.courseDescriptionBox.secondParagraph"
                            />
                        </p>
                        <p>
                            <Trans 
                                i18nKey="companiesPage.courseDescriptionBox.thirdParagraph"
                            />
                        </p>
                        <p>
                            <Trans 
                                i18nKey="companiesPage.courseDescriptionBox.fourthParagraph"
                            />
                        </p>
                    </div>
                </div>
            </div>
            <div className='col-12 col-md-4 mt-4 mt-md-0'>
                <div className='features-box text-start'>
                    <h2>
                        <Trans 
                            i18nKey="companiesPage.featuresBox.title"
                        />
                    </h2>
                    <div className='row mt-4'>
                        <div className='col-2'>
                            <i className='bi bi-stopwatch me-2'></i>
                        </div>
                        <div className='col-10'>
                            <Trans 
                                i18nKey="companiesPage.featuresBox.firstFeature"
                            />
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-2'>
                            <i className='bi bi-calendar-check'></i>
                        </div>
                        <div className='col-10'>
                            <Trans 
                                i18nKey="companiesPage.featuresBox.secondFeature"
                            />
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-2'>
                            <i className='bi bi-people'></i>
                        </div>
                        <div className='col-10'>
                            <Trans 
                                i18nKey="companiesPage.featuresBox.thirdFeature"
                            />
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-2'>
                            <i className='bi bi-geo-alt'></i>
                        </div>
                        <div className='col-10'>
                            <Trans 
                                i18nKey="companiesPage.featuresBox.fourthFeature"
                            />
                        </div>
                    </div>
                </div>
                <div className='features-box text-start mt-4'>
                    <h2>
                        <Trans 
                            i18nKey="companiesPage.areYouInterestedBox.title"
                        />
                    </h2>
                    <p className='mt-4'>
                        <Trans 
                            i18nKey="companiesPage.areYouInterestedBox.text"
                        />
                    </p>
                    <button 
                        className='btn btn-primary mt-4 contact-button'
                        onClick={() => window.open('/contact-us?scrollTo=contact-form', '_self')}
                    >
                        <Trans 
                            i18nKey="companiesPage.areYouInterestedBox.button"
                        />
                    </button>    
                </div>
                <div className='features-box text-start mt-4'>
                    <h2>
                        <Trans 
                            i18nKey="companiesPage.freeTrialBox.title"
                        />
                    </h2>
                    <p className='mt-4'>
                        <Trans 
                            i18nKey="companiesPage.areYouInterestedBox.text"
                        />
                    </p>
                    <button 
                        className='btn btn-primary mt-4 contact-button'
                        onClick={() => window.open('/booking?classType=freeTrial', '_self')}
                    >
                        <Trans 
                            i18nKey="companiesPage.areYouInterestedBox.button"
                        />
                    </button>    
                </div>
            </div>
        </div>
    </div>
};

export default PricesForCompaniesSection;