import React from 'react';
import { Trans } from 'react-i18next';

import './index.scss';
import { ColoredButton } from '../../components/ColoredButton';
//import { useNavigate } from 'react-router-dom';
import { postLog } from "../../services";


export const IntroSection = () => {

    //const navigate = useNavigate()

    const handleCTAClick = () => {
        postLog('click', 'button', 'cta_intro_section');
        //navigate('/booking?classType=freeTrial');
        window.open('/booking?classType=freeTrial', '_self');
    }

    return <div className='row mx-0 text-center intro-section-container'>
        <p className='intro-section-title'>¡Hola!</p>
        <p className='intro-section-text mt-3'>
            <Trans 
                i18nKey="coursesPage.intro.firstParagraph"
                components={{
                    red: <span className='red' />
                }}
            />
        </p>
        <p className='intro-section-text mt-3'>
            <Trans 
                i18nKey="coursesPage.intro.secondParagraph"
                components={{
                    red: <span className='red' />,
                    yellow: <span className='yellow' />,
                    bold: <b />
                }}
            />
        </p>
        <p className='intro-section-text mt-3'>
            <Trans 
                i18nKey="coursesPage.intro.thirdParagraph"
                components={{
                    red: <span className='red' />,
                    yellow: <span className='yellow' />,
                    bold: <b />
                }}
            />
        </p>
        <div className='mt-5'>
            <ColoredButton
                onClick={handleCTAClick}
                size='large'
            >
            <Trans 
                i18nKey="coursesPage.intro.CTAButton"
            />
            </ColoredButton>
        </div>
    </div>
}

export default IntroSection;