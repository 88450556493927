import { axios } from './index';


export const postLog = async (action, paramName, param) => {
    const log = { 
        'user_id': null,
        'action': action,
        'param_name': paramName,
        'param': param 
    };

    // only post logs in production and if user consented to the use of cookies
    if (process.env.NODE_ENV === 'production') {
        axios.post('/log', log)
    }
}