import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import { postUserSession, postUserDoc } from '../../services';
import { useAdminStore } from '../../store';


export const InputTypeSwitch = ({ propertyName, inputIndex }) => {

    const { 
        deleteAllEditableUserSessions, userSessionFormData, setUserSessionFormData,
        resetUserSessionFormData, userSessionFile, setUserSessionFile 
    } = useAdminStore();

    const [ formDataUploading, setFormDataUploading ] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId');

    // logic performed anytime a regular input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserSessionFormData({ ...userSessionFormData, [name]: value });
    };

    // logic performed anytime a file input changes
    const handleFileInputChange = (e) => {
        // get file from form
        const file = e.target.files[0];
        setUserSessionFormData({ ...userSessionFormData, homework_filename: file.name });
        setUserSessionFile(file);
    };

    const handleDeleteNewSession = () => {
        deleteAllEditableUserSessions();
        resetUserSessionFormData();
    };

    // function triggered when user confirms a session
    const handleConfirmNewSession = async (e) => {
        e.preventDefault();
        // if at least session id was filled out:
        const file = userSessionFile;
        if (userSessionFormData.session_id !== '') {
            setFormDataUploading(true);
            // post session to db
            postUserSession(
                userId, userSessionFormData.session_id, 
                userSessionFormData.session_date, 
                userSessionFormData.session_content, 
                userSessionFormData.session_notes
            ).then(() => {
                // if document was uploaded, post it to S3
                if (file) {
                    postUserDoc(
                        userId, userSessionFormData.session_id, file
                    ).then(() => {
                        window.location.href = `/dashboard?userId=${userId}`;
                        setFormDataUploading(false);
                    })
                } else {
                    window.location.href = `/dashboard?userId=${userId}`;
                    setFormDataUploading(false);
                }

                // delete editable session
                deleteAllEditableUserSessions();
                // reset form data
                resetUserSessionFormData();
                setUserSessionFile(null);
            })
        };
    };

    switch (propertyName) {
        case 'session_id':
            return <td key={inputIndex}>
                <input 
                    type='text' 
                    className='small-input'
                    name={propertyName}
                    value={userSessionFormData[propertyName]}
                    onChange={handleInputChange}
                />
            </td>
        case 'session_date':
            return <td key={inputIndex}>
                <input 
                    type='text'
                    className='medium-input'
                    name={propertyName}
                    value={userSessionFormData[propertyName]}
                    onChange={handleInputChange}
                />
            </td>
        case 'session_content':
            return <td key={inputIndex}>
                <textarea 
                    type='text'
                    rows='5'
                    name={propertyName}
                    value={userSessionFormData[propertyName]}
                    onChange={handleInputChange}
                />
            </td> 
        case 'homework_filename':
            return <td key={inputIndex}>
                {!userSessionFile ? <input 
                    type='file'
                    name={propertyName}
                    //value={userSessionFormData[propertyName]}
                    onChange={handleFileInputChange}
                /> : userSessionFile.name}
            </td> 
        case 'session_notes':
            return <>
                <td key={inputIndex}>
                    <textarea 
                        type='text'
                        rows='5'
                        name={propertyName}
                        value={userSessionFormData[propertyName]}
                        onChange={handleInputChange}
                    />
                </td>
                <td>
                    <div>
                        { !formDataUploading  && <>
                            <i
                                className='ms-3 bi bi-check-circle-fill confirm-icon'
                                onClick={handleConfirmNewSession}
                            ></i>
                            <i
                                className='ms-3 bi bi-x-circle-fill delete-icon'
                                onClick={handleDeleteNewSession}
                            ></i>
                        </> }
                        <ClipLoader
                            color={'black'}
                            loading={formDataUploading}
                            size={20}
                        />
                    </div>
                </td>
            </> 
        default:
            return null
    }
};

export default InputTypeSwitch;