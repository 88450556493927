import React from 'react';
import { Trans } from 'react-i18next';

import './index.scss';
import { useCookiesConsentStore } from '../../store';


export const CookiesDisclaimer = () => {

    const setCookiesConsent = useCookiesConsentStore(state => state.setCookiesConsent);

    const handleRejectCookies = () => {
        setCookiesConsent(false);
        localStorage.setItem('cookiesConsent', false)
    }

    const handleAcceptCookies = () => {
        setCookiesConsent(true);
        localStorage.setItem('cookiesConsent', true)
    }

    return <div className='cookies-disclaimer'>
        <div className='cookies-disclaimer-header'>
            <span className='cookies-disclaimer-logo'>Corivia</span>
            <i 
                class='bi bi-x-lg close-disclaimer-icon'
                onClick={handleRejectCookies}
            >
            </i>
        </div>
        <div className='cookies-disclaimer-body'>
            <div>
                <p className='cookies-disclaimer-title'><Trans i18nKey='cookiesDisclaimer.title'/></p>
                <p>
                    <Trans 
                        i18nKey='cookiesDisclaimer.text'
                        components={{
                            linkToPolicy: <a 
                                href='/cookies_policy.pdf' 
                                target='_blank' 
                                rel='noreferrer'
                            />
                        }}
                    />
                </p>
            </div>
            <div className='row m-0 p-0 mt-4'>
                <div className='col-6 col-lg-8 m-0 p-0'>
                    <button className='gray'>
                        <a href='/cookies_policy.pdf' target='_blank' rel='noreferrer'>
                            <Trans 
                                i18nKey='cookiesDisclaimer.findOutMoreButton'
                            />
                        </a>
                    </button>
                </div>
                <div className='col-3 col-lg-2 text-end m-0 p-0'>
                    <button onClick={handleRejectCookies}>
                        <Trans 
                            i18nKey='cookiesDisclaimer.rejectButton'
                        />
                    </button>
                </div>
                <div className='col-3 col-lg-2 text-end'>
                    <button onClick={handleAcceptCookies}>
                        <Trans 
                            i18nKey='cookiesDisclaimer.acceptButton'
                        />
                    </button>
                </div>
            </div>
        </div>
    </div>
}

export default CookiesDisclaimer;