import React, { useEffect } from 'react';

import './index.scss';
import { Navbar } from '../../layouts/Navbar';
import { SmallHeroSection } from '../../layouts/SmallHeroSection';
import { BookingSection } from '../../layouts/BookingSection';
import { Footer } from '../../layouts/Footer';


export const BookingPage = () => {

    useEffect(() => {
        const targetDiv = document.getElementById('booking-section-title');

        if (targetDiv) {
            targetDiv.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, []);

	return <div>
		<Navbar />
        <SmallHeroSection 
            titleI18nKey='bookingPage.title'
            subtitleI18nKey='bookingPage.subtitle'
		/>
        <BookingSection />
		<Footer />
	</div>
}

export default BookingPage;