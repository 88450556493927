import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { verifyAuth, refreshToken } from "../../services";


// user must be logged in to access the route contained in this component
export const ProtectedRoutes = () => {

    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    // on component mount, check if user is authenticated by invoking the 
    // authentication algorithm
    useEffect(() => {
        // the authentication algorithm
        const fetchAuthentication = async () => {
            try {
                const response = await verifyAuth();
                // if call to verification endpoint is successful, user is
                // authenticated; if not, token may have expired
                if (response.code === 200) {
                    setAuthenticated(true);
                }
            } catch (error) {
                // if the token has expired, attempt to refresh it
                if (error.response.status === 401 && 
                    error.response.data.error === 'Token has expired'
                ) {
                    try {
                        const refreshResponse = await refreshToken();

                        // retry the verification request with the new token
                        const retryResponse = await verifyAuth();
                        // if request with refreshed token is successful, user is
                        // authenticated
                        setAuthenticated(true);
                    } catch (refreshError) {
                        // if request with refreshed token throws a new error, user
                        // is not authenticated
                        console.error(
                            'Token refresh failed:', refreshError.response.data.message
                        );
                        setAuthenticated(false);
                    }
                }
            }
            setLoading(false);
        };

        fetchAuthentication();

    }, [])

    // only allow authenticated users to visit page
    return loading ? 
        <div>Loading...</div> : authenticated ? 
            <Outlet /> : <Navigate to='/login' />;
}

export default ProtectedRoutes;