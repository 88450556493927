import React from 'react';
import { Trans } from 'react-i18next';

import './index.scss';
import { useSystemStore, useAdminStore } from '../../store';
import { InputTypeSwitch } from './InputTypeSwitch';


export const AdminUserSessionsTable = () => {

    const { listOfSessions } = useSystemStore();
    const { editableUserSessions } = useAdminStore();
    
    const tableRows = [
        'session_date', 'session_content', 'homework_filename', 'session_notes'
    ];

    return <table className='table'>
        <thead>
            <tr>
                <th scope='column'>
                    <Trans 
                        i18nKey={
                            `dashboardPage.userSessionsTable.columnsHeading.firstColumn`
                        }
                    />
                </th>
                <th scope='column'>
                    <Trans 
                        i18nKey={
                            `dashboardPage.userSessionsTable.columnsHeading.secondColumn`
                        }
                    />
                </th>
                <th scope='column'>
                    <Trans 
                        i18nKey={
                            `dashboardPage.userSessionsTable.columnsHeading.thirdColumn`
                        }
                    />
                </th>
                <th scope='col'>
                    <Trans 
                        i18nKey={
                            `dashboardPage.userSessionsTable.columnsHeading.fourthColumn`
                        }
                    />
                </th>
                <th scope='col'>
                    <Trans 
                        i18nKey={
                            `dashboardPage.userSessionsTable.columnsHeading.fifthColumn`
                        }
                    />
                </th>
            </tr>
        </thead>
        <tbody>
            {/* Regular table rows */}
            { listOfSessions && listOfSessions.map((session, outerIndex) => (
                <tr key={outerIndex}>
                    <th scope='row'>{session.session_id}</th>
                    { tableRows && tableRows.map((key, innerIndex) => {
                        if (key === 'homework_filename') {
                            // file cell: display file name
                            return <td 
                                key={innerIndex}
                                className='homework-name'
                            >
                                {session[key]}
                            </td>
                        } else {
                            // regular cell: display text
                            return <td 
                                key={innerIndex} 
                            >
                                {session[key]}
                            </td>
                        }
                    }) }
                </tr>
            )) }
            {/* editable rows */}
            { editableUserSessions.map((session, key) => (
                <tr key={key}>
                    {Object.keys(session).map((key, index) => (
                        <InputTypeSwitch 
                            propertyName={key}
                            inputIndex={index}
                            key={index}
                        />
                    ))}
                </tr>
            )) }
        </tbody>
    </table>

};

export default AdminUserSessionsTable