import React, { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';

import './index.scss';
import { getUserSessions } from '../../services';
import { UserSessionsTable } from '../UserSessionsTable';
import { useSystemStore } from '../../store';


export const StudentDashboard = () => {

    const userInfo = useSystemStore(state => state.userInfo);
    const [ userSessions, setUserSessions ] = useState(null);

    // on page load, display user sessions
    useEffect(() => {
        const displaySessions = async () => {
            const sessions = await getUserSessions();
            setUserSessions(sessions);
        }

        displaySessions();
    }, [])

    return <div className='student-dashboard-container'>
        {/* Student details */}
        <div className='row'>
            <div className='col-12 col-lg-4 mb-5 mb-lg-0'>
                <div className='row m-0 p-0 mb-4 student-dashboard-heading'>
                    <Trans 
                        i18nKey='dashboardPage.userDashboard.welcomeText'
                    />
                </div>
                <div className='row m-0 p-0'>
                    {userInfo.firstName} {userInfo.lastName}
                </div>
            </div>
            <div className='col-8'>
                <div className='row m-0 p-0 mb-4 student-dashboard-heading'>
                    <Trans 
                        i18nKey='dashboardPage.userDashboard.meetingLinkText'
                    />
                </div>
                <div className='row m-0 p-0'>
                    {
                        userInfo.meetingLink ?
                        <a 
                            href={userInfo.meetingLink} 
                            target='_blank' 
                            rel='noreferrer'
                            className='m-0 p-0 lesson-link'
                        >
                            {userInfo.meetingLink}
                        </a> :
                        <div className='resource-not-available-text m-0 p-0'>
                            <Trans 
                                i18nKey={
                                    `dashboardPage.userDashboard.noLessonLinkAvailableText`
                                }
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
        {/* Student sessions table */}
        {
            userSessions && userSessions.length > 0 ? <div className='mt-5'>
                <hr />
                <div className='mb-5 mt-5 student-dashboard-heading'>
                    <Trans 
                        i18nKey='dashboardPage.userDashboard.yourSessionsText'
                    />
                </div>
                <UserSessionsTable 
                    userSessions={userSessions}
                />
            </div> :
            <div className='not-available-text-container'>
                <div className='resource-not-available-text'>
                    <Trans 
                        i18nKey='dashboardPage.userDashboard.noSessionsAvailableText'
                    />
                </div>
            </div>
        }
    </div>
}

export default StudentDashboard;