import React, { useEffect } from 'react';

import './index.scss';
import { SmallHeroSection } from '../../layouts/SmallHeroSection';
import { Navbar } from '../../layouts/Navbar';
import { Footer } from '../../layouts/Footer';
import { AboutUsSection } from '../../layouts/AboutUsSection';


export const AboutUsPage = () => {

	useEffect(() => {
		window.scrollTo(0, 0);
	})

	return <div>
		<Navbar />
		<div className='primary-background'>
			<SmallHeroSection 
				titleI18nKey='aboutUsPage.title'
				subtitleI18nKey='aboutUsPage.subtitle'
			/>
			<AboutUsSection />
		</div>
		<Footer />
	</div>
}

export default AboutUsPage;
