import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Trans } from 'react-i18next';

import './index.scss';
import { useAdminStore, useSystemStore } from '../../store';
import { getUsers, getSessionsByUserId } from '../../services';
import { AdminUserSessionsTable } from '../AdminUserSessionsTable';


export const AdminDashboard = () => {

    const [ selectedUserEmail, setSelectedUserEmail ] = useState('');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId');

    const { 
        listOfStudents, setListOfStudents, listOfSessions, setListOfSessions,
    } = useSystemStore();
    const { 
        editableUserSessions, addNewUserSession, deleteAllEditableUserSessions,
    } = useAdminStore();

    useEffect(() => {
        // set selected user email based on user id query parameter
        if ( userId && listOfStudents ) {
            setSelectedUserEmail(listOfStudents.find(
                o => o.user_id === userId
            ).user_email);
        }
    }, [userId, listOfStudents])

    // on page load, get list of all students
    useEffect(() => {
        const getListOfStudents = async () => {
            const listOfStudents = await getUsers('student')
            setListOfStudents(listOfStudents);
        }

        getListOfStudents();
    }, [])

    // upon user selection, display its list of sessions
    useEffect(() => {
        const displaySessions = async () => {
            const sessions = await getSessionsByUserId(userId);
            setListOfSessions(sessions);
        };

        if ( userId ) {
            displaySessions();
        };
    }, [ userId ]);

    const handleUserSelection = (event) => {
        let userEmail = event.target.value;
        setSelectedUserEmail(userEmail);

        // get user id by user email
        const userId = listOfStudents.find(
            o => o.user_email === userEmail
        ).user_id;
        window.location.href = `/dashboard?userId=${userId}`;
    };

    // create a new session; it will be displayed below the last 
    // available session
    const handleCreateNewSession = () => {
        addNewUserSession();
    };


    return <div className='admin-dashboard-container'>
        {/* User selection */}
        { (listOfStudents && listOfStudents.length > 0) ? <>
            <div>
                <Trans 
                    i18nKey='dashboardPage.adminDashboard.userSelectionPrompt'
                />
            </div>
            <div className='mt-3'>
                <select
                    className={`${editableUserSessions.length > 0  && 'disabled'}`}
                    value={selectedUserEmail}
                    onChange={handleUserSelection}
                >
                    <option hidden>
                        <Trans 
                            i18nKey='dashboardPage.adminDashboard.userSelectionPrompt'
                        />
                    </option>
                    {listOfStudents.map((option, key) => (
                        <option 
                            key={option.user_id} 
                            value={
                                option.user_email
                            }
                        >
                            {option.user_email}
                        </option>
                    ))}
                </select>
            </div>
        </> :'Loading...' }
        {/* Student sessions table and add session button; both are only
         visible when a user is selected */}
        { listOfSessions && userId && <div className='mt-5'>
            <AdminUserSessionsTable />
            <div className='mt-3 text-center'>
                <i 
                    className={
                        // activate button only if no editable rows are present
                        `bi bi-plus-circle-fill add-icon 
                        ${editableUserSessions.length > 0  && 'disabled'}`
                    }
                    onClick={handleCreateNewSession}
                ></i>
            </div>
        </div> }
    </div>
}

export default AdminDashboard;