import React from 'react';

import './index.scss';
import { Navbar } from '../../layouts/Navbar';
import { SmallHeroSection } from '../../layouts/SmallHeroSection';
import { PricesForCompaniesSection } from '../../layouts/PricesForCompaniesSection';
import { Footer } from '../../layouts/Footer';


export const CompaniesPage = () => {
    return <div>
		<Navbar />
		<SmallHeroSection 
			titleI18nKey='companiesPage.title'
			subtitleI18nKey='companiesPage.subtitle'
		/>
        <PricesForCompaniesSection />
		<Footer />
    </div>
}

export default CompaniesPage;