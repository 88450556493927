import React, { useEffect } from 'react';

import './index.scss';
import { DashboardNavbar } from '../../components/DashboardNavbar';
import { useSystemStore } from '../../store';
import { userInfo } from '../../services';
import { ADMIN_ROLE, STUDENT_ROLE } from '../../consts';
import { AdminDashboard } from '../../components/AdminDashboard';
import { StudentDashboard } from '../../components/StudentDashboard';


export const DashboardPage = () => {

    const setUserRole = useSystemStore(state => state.setUserRole);
    const userRole = useSystemStore(state => state.userRole);
    const setUserInfo = useSystemStore(state => state.setUserInfo);

    // on page load, fetch user information and set it in the store
    useEffect(() => {
        const getUserInfo = async () => {
            const userData = await userInfo();
            setUserInfo(
                userData.email, userData.first_name, userData.last_name, 
                userData.meeting_link
            )
            setUserRole(userData.role);
        }

        getUserInfo();

    }, [])

    let dashboard;
    switch (userRole) {
        case ADMIN_ROLE:
            dashboard = <AdminDashboard />;
            break;
        case STUDENT_ROLE:
            dashboard = <StudentDashboard />;
            break;
        default:
            dashboard = <StudentDashboard />;
    }

    return <div>
        <DashboardNavbar />
        { dashboard }
    </div>
}

export default DashboardPage;