import { awsApi } from './index';


export const postEmail = async (name, emailAddress, message) => {
    const email = { 
        'name': name,
        'email_address': emailAddress,
        'message': message
    };

    awsApi.post('/email', email)
}