import React from 'react';
//import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';

import './index.scss';
import { BoxWithCTAButton } from '../../components/BoxWithCTAButton';
import { postLog } from '../../services';
import course_1 from '../../assets/course_1.png';
import course_2 from '../../assets/course_2.png';
import course_3 from '../../assets/videoconference.jpg';


export const CoursesDescriptionSection = () => {

    //const navigate = useNavigate();

    return <div className='text-center courses-description-section'>
        <div className='courses-description-intro'>
            <h1>
                <Trans 
                    i18nKey='coursesPage.coursesDescriptionSection.title'
                />
            </h1>
            <div className='mx-2 mx-lg-5'>
                <p className='mt-5'>
                    <Trans 
                        i18nKey='coursesPage.coursesDescriptionSection.subtitle.firstParagraph'
                    />
                </p>
                <p>
                    <Trans 
                        i18nKey='coursesPage.coursesDescriptionSection.subtitle.secondParagraph'
                        components={{
                            bold: <b />
                        }}
                    />
                </p>
            </div>
        </div>
        <div id='private-classes-box'>
            <BoxWithCTAButton
                i18nKey='coursesPage.coursesDescriptionSection.CTAButton'
                handleCTAButtonClick={
                    () => {
                        postLog('click', 'button', 'book_now_private_classes');
                        //navigate('/prices?scrollTo=adults-children-classes')
                        window.open('/booking?classType=freeTrial', '_self');
                    }
                }
            >
                <h1>
                    <Trans 
                        i18nKey='coursesPage.coursesDescriptionSection.privateLessonsCard.title'
                    />
                </h1>
                <h4 className='mt-4'>
                    <Trans 
                        i18nKey='coursesPage.coursesDescriptionSection.privateLessonsCard.subtitle'
                        components={{
                            bold: <b />
                        }}
                    />
                </h4>
                <div className='row mt-5'>
                    <div className='col-lg-4 col-md-12'>
                        <img src={course_1} alt='corsi di Spagnolo' width='100%' />
                    </div>
                    <div className='col-lg-8 col-md-12 ps-md-5 mt-5 mt-lg-0'>
                        <div className='course-description-container text-start'>
                            <ul>
                                <li>
                                    <Trans 
                                        i18nKey='coursesPage.coursesDescriptionSection.privateLessonsCard.firstBullet'
                                    />
                                </li>
                                <li>
                                    <Trans 
                                        i18nKey='coursesPage.coursesDescriptionSection.privateLessonsCard.secondBullet'
                                    />
                                </li>
                                <li>
                                    <Trans 
                                        i18nKey='coursesPage.coursesDescriptionSection.privateLessonsCard.thirdBullet'
                                    />
                                </li>
                                <li>
                                    <Trans 
                                        i18nKey='coursesPage.coursesDescriptionSection.privateLessonsCard.fourthBullet'
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </BoxWithCTAButton>
        </div>
        <div id='conversation-classes-box' className='extra-margin-top'>
            <BoxWithCTAButton
                i18nKey='coursesPage.coursesDescriptionSection.CTAButton'
                handleCTAButtonClick={
                    () => {
                        postLog('click', 'button', 'book_now_conversation_classes');
                        //navigate('/prices?scrollTo=conversation-classes')
                        window.open('/booking?classType=freeTrial', '_self');
                    }
                }
            >
                <h1>
                    <Trans 
                        i18nKey='coursesPage.coursesDescriptionSection.conversationLessonsCard.title'
                    />
                </h1>
                <h4 className='mt-4'>
                    <Trans 
                        i18nKey='coursesPage.coursesDescriptionSection.conversationLessonsCard.subtitle'
                        components={{
                            italic: <i />
                        }}
                    />
                </h4>
                <div className='row mt-5 flex-column-reverse flex-md-row'>
                    <div className='col-lg-8 col-md-12 pe-lg-5'>
                        <div className='course-description-container text-start'>
                            <ul>
                                <li>
                                    <Trans 
                                        i18nKey='coursesPage.coursesDescriptionSection.conversationLessonsCard.firstBullet'
                                    />
                                </li>
                                <li>
                                    <Trans 
                                        i18nKey='coursesPage.coursesDescriptionSection.conversationLessonsCard.secondBullet'
                                    />
                                </li>
                                <li>
                                    <Trans 
                                        i18nKey='coursesPage.coursesDescriptionSection.conversationLessonsCard.thirdBullet'
                                    />
                                </li>
                                <li>
                                    <Trans 
                                        i18nKey='coursesPage.coursesDescriptionSection.conversationLessonsCard.fourthBullet'
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-12 mb-5 mb-lg-0'>
                        <img src={course_2} alt='corsi di Spagnolo' width='100%' />
                    </div>
                </div>
            </BoxWithCTAButton>
        </div>
        <div id='group-classes-box' className='extra-margin-top mb-5'>
            <BoxWithCTAButton
                i18nKey='coursesPage.coursesDescriptionSection.CTAButton'
                handleCTAButtonClick={
                    () => {
                        postLog('click', 'button', 'book_now_children_classes');
                        //navigate('/prices?scrollTo=adults-children-classes');
                        window.open('/booking?classType=freeTrial', '_self');
                    }
                }
            >
                <h1>
                    <Trans 
                        i18nKey='coursesPage.coursesDescriptionSection.groupLessonsCard.title'
                    />
                </h1>
                <h4 className='mt-4'>
                    <Trans 
                        i18nKey='coursesPage.coursesDescriptionSection.groupLessonsCard.subtitle'
                    />
                </h4>
                <div className='row mt-5'>
                    <div className='col-lg-4 col-md-12 mb-5 mb-lg-0'>
                        <img src={course_3} alt='corsi di Spagnolo' width='100%' />
                    </div>
                    <div className='col-lg-8 col-md-12 ps-lg-5'>
                        <div className='course-description-container text-start'>
                            <ul>
                                <li>
                                    <Trans 
                                        i18nKey='coursesPage.coursesDescriptionSection.groupLessonsCard.firstBullet'
                                    />
                                </li>
                                <li>
                                    <Trans 
                                        i18nKey='coursesPage.coursesDescriptionSection.groupLessonsCard.secondBullet'
                                    />
                                </li>
                                <li>
                                    <Trans 
                                        i18nKey='coursesPage.coursesDescriptionSection.groupLessonsCard.thirdBullet'
                                    />
                                </li>
                                <li>
                                    <Trans 
                                        i18nKey='coursesPage.coursesDescriptionSection.groupLessonsCard.fourthBullet'
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </BoxWithCTAButton>
        </div>
    </div>
}

export default CoursesDescriptionSection;