import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';

import './index.scss';
import { userLogin } from '../../services';


export const LoginForm = () => {
    
    const { t } = useTranslation();
    //const navigate = useNavigate();

    const initialFormData = {
        emailAddress: '',
        password: ''
    }
    const initialValidationErrors = {
        emailAddress: '',
        password: ''
    }

    const [ formData, setFormData ] = useState(initialFormData);
    const [ validationErrors, setValidationErrors ] = useState(
        initialValidationErrors
    );
    const [ loggingIn, setLoggingIn ] = useState(false);


    // set form data as user types
    const handleFormTyping = (event) => {

        // reset errors once user types something new
        setValidationErrors(initialValidationErrors)

        const { name, value } = event.target;

        setFormData((prevData) => ({
            ...prevData,
            // dynamically set initialFormData object key
            [name]: value,
        }))
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        // reset any error that may have been thrown on last submit
        let validationError = false;
        setValidationErrors(initialValidationErrors);

        // validate form by iterating over formData keys. 
        // Fields must not be null
        Object.keys(formData).forEach(
            (key) => {
                if (formData[key] === '') {
                    setValidationErrors((prevState) => ({
                        ...prevState,
                        [key]: 'nullValue'
                    }));
                    validationError = true;
                }
            }
        )

        if (!validationError) {
            try {
                setLoggingIn(true);
                await userLogin(
                    formData['emailAddress'], formData['password']
                );
                //navigate('/dashboard');
                window.open('/dashboard', '_self');
                setLoggingIn(false);
            } catch (error) {
                // either email address or password are wrong, login failed
                if (error.response.status === 404) {
                    setValidationErrors((prevState) => ({
                        ...prevState,
                        emailAddress: 'notExists'
                    }));
                } else if (error.response.status === 401) {
                    setValidationErrors((prevState) => ({
                        ...prevState,
                        password: 'wrong'
                    }));
                }
                setLoggingIn(false);
            }
        }

        // reset form
        //setFormData(initialFormData)
    }

    return <div className='login-form-container'>
        <h3>
            <Trans 
                i18nKey={'loginPage.title'}
            />
        </h3>
        <p className='mt-4'>
            <Trans 
                i18nKey={'loginPage.subtitle'}
            />
        </p>
        <form onSubmit={handleFormSubmit}>
            <div className='form-group mt-4'>
                <div className='row'>
                    <div className='col-4'>
                        <label htmlFor='inputEmail'>
                            <Trans 
                                i18nKey={'loginPage.emailBox.title'}
                            />
                        </label>
                    </div>
                    <div className='col-8'>
                        {/* Email address input is empty */}
                        { validationErrors['emailAddress'] === 'nullValue' && 
                            <div className='error-message'>
                                <Trans 
                                    i18nKey={'loginPage.emailBox.noEmailError'}
                                />
                            </div> }
                        {/* Email address was not found */}
                        { validationErrors['emailAddress'] === 'notExists' && 
                            <div className='error-message'>
                                <Trans 
                                    i18nKey={'loginPage.emailBox.wrongEmailError'}
                                />
                            </div> }
                    </div>
                </div>
                <input
                    type='email'
                    name='emailAddress'
                    placeholder={
                        t('loginPage.emailBox.placeholder')
                    }
                    value={formData.emailAddress}
                    onChange={handleFormTyping}
                    className={
                        `form-control ${validationErrors['emailAddress'] !== '' && 
                        'error-red-border'}`
                    }
                />
            </div>
            <div className='form-group mt-4'>
                <div className='row'>
                    <div className='col-4'>
                        <label htmlFor='inputPassword'>
                            <Trans 
                                i18nKey={'loginPage.passwordBox.title'}
                            />
                        </label>
                    </div>
                    <div className='col-8'>
                        {/* Password input is empty */}
                        { validationErrors['password'] === 'nullValue' && 
                            <div className='error-message'>
                                <Trans 
                                    i18nKey={'loginPage.passwordBox.noPasswordError'}
                                />
                            </div> }
                        {/* Email address is correct, password is wrong */}
                        { validationErrors['password'] === 'wrong' && 
                            <div className='error-message'>
                                <Trans 
                                    i18nKey={'loginPage.passwordBox.wrongPasswordError'}
                                />
                            </div> }
                    </div>
                </div>
                <input 
                    type='password' 
                    name='password'
                    className={
                        `form-control ${validationErrors['password'] !== '' && 
                        'error-red-border'}`
                    }
                    placeholder={
                        t('loginPage.passwordBox.placeholder')
                    }
                    value={formData.password}
                    onChange={handleFormTyping}
                />
            </div>
            <button type='submit' className='btn login-button mt-5'>
                <Trans 
                    i18nKey={'loginPage.loginButton'}
                />
                <ClipLoader
                    color={'white'}
                    loading={loggingIn}
                    size={15}
                    className='ms-3'
                />
            </button>
        </form>
        <p className='mt-4 text-center'>
            <Trans 
                i18nKey={'loginPage.redirectToSignup'}
                components={{
                    linkToSignup: <a 
                        href='/signup' 
                    />
                }}
            />
        </p>
    </div>
}

export default LoginForm;