import React from 'react';
import { Trans } from 'react-i18next';

import './index.scss';


export const HeroSection = () => {

    return <div className='hero-section'>
        <div className='jumbotron jumbotron-fluid text-center'>
            <div className='container'>
                <p className='hero-title'>
                    {/*Impara lo <span className='red'>Spa</span>
                    <span className='yellow'>gno</span>
                    <span className='red'>lo</span>*/}
                    <Trans 
                        i18nKey="heroSection.title"
                        components={{
                            red: <span className='red' />, 
                            yellow: <span className='yellow' />
                        }}
                    />
                </p>
                <p className='hero-subtitle mt-4'>
                    {/*<span className='red'>Come</span>, 
                    <span className='yellow'> quando</span> e 
                    <span className='red'> dove</span> vuoi */}
                    <Trans 
                        i18nKey="heroSection.subtitle"
                        components={{
                            red: <span className='red' />, 
                            yellow: <span className='yellow' />
                        }}
                    />
                </p>
            </div>
        </div>
    </div>
}

export default HeroSection;