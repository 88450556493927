import React from 'react';
import { useLocation } from "react-router-dom";
import { Trans } from 'react-i18next';

import './index.scss';


export const BookingSection = () => {

    const location = useLocation();
    const classType = new URLSearchParams(location.search).get('classType') || 'regular';

    const CalendlyEmbed = () => {
    
        return <div className='calendly-container'>
            <iframe
                title='calendly-container'
                id='iframe'
                src="https://calendly.com/corivia_es/clases-de-espanol-corivia"
                width="100%"
                height="100%"
                frameBorder="0"
            ></iframe>
        </div>
    }

    return <div className='booking-section text-center' id='booking-section-title'>
        <h1 className='mt-5 mb-5 mb-lg-0'>
            {
                classType === 'freeTrial' ? 
                <Trans 
                    i18nKey="bookingPage.bookingSection.titleFreeTrial"
                /> :
                <Trans 
                    i18nKey="bookingPage.bookingSection.title"
                />
            }
        </h1>
        {
            classType === 'freeTrial' &&
            <h4 className='mt-5 mb-5 mb-lg-0'>
                <Trans 
                    i18nKey="bookingPage.bookingSection.subtitle"
                />
            </h4>
        }
        <CalendlyEmbed />
    </div>
}

export default BookingSection;