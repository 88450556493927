import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import './index.scss';
import { SmallHeroSection } from '../../layouts/SmallHeroSection';
import { Navbar } from '../../layouts/Navbar';
import { Footer } from '../../layouts/Footer';
import { ContactUsSection } from '../../layouts/ContactUsSection';


export const ContactUsPage = () => {

    const location = useLocation();
    const scrollToId = new URLSearchParams(location.search).get('scrollTo');

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [])

    // if the 'scrollTo' query param is present, scroll to the div
    // with that id
    useEffect(() => {
        const targetDiv = document.getElementById(scrollToId);

        if (targetDiv) {
            targetDiv.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [ scrollToId ]);

	return <div>
		<Navbar />
		<SmallHeroSection 
			titleI18nKey='contactsPage.title'
			subtitleI18nKey='contactsPage.subtitle'
		/>
		<div className='primary-background'>
			<ContactUsSection />
		</div>
		<Footer />
	</div>
}

export default ContactUsPage;
