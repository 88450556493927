import { axios } from './index';


// ALL OF THE BELOW SERVICES REQUIRE USER TO BE OF ROLE 'ADMIN'  

export const getUsers = async ( userRole ) => {    
    // Get list of users having a given role

    // REQUIRES AUTHENTICATION

    const response = await axios.get(
        `admin/user`, 
        { params: { user_role: userRole }, 
        withCredentials: true }
    );

    return response.data;
}

export const getSessionsByUserId = async ( userId ) => {
    // get all sessions for user with a given id

    // REQUIRES AUTHENTICATION

    const response = await axios.get(
        `admin/user/${userId}/session/all`,
        { withCredentials: true }
    );

    return response.data;
}

export const postUserDoc = async ( userId, sessionId, file ) => {
    // post a user document to S3, given its user id and its session id

    // REQUIRES AUTHENTICATION

    // input must be a File object
    let formData = new FormData();
    formData.append('file', file);

    const response = await axios.post(
        `/admin/user/${userId}/session/${sessionId}/doc`, 
        formData, 
        {
            withCredentials: true,
            headers: {
                'content-type': file.type,
            },
        }
    );

    return response.data
}

export const getDocPresignedUrl = async ( userId, sessionId, filename ) => {
    // get pre-signed URL to download a document, given its s3 key

    // REQUIRES AUTHENTICATION

    const data = {
        'user_id': userId,
        'session_id': sessionId,
        'filename': filename
    }
    // get file from S3
    const response = await axios.post(
        `admin/doc/download`, data, { withCredentials: true }
    );

    return response.data
}

export const postUserSession = async (
    userId, sessionId, sessionDate, sessionContent, sessionNotes 
) => {
    // post a user session

    // REQUIRES AUTHENTICATION

    const data = {
        session_id: sessionId,
        session_date: sessionDate,
        session_content: sessionContent,
        homework_filename: '',
        session_notes: sessionNotes
    }

    const response = await axios.post(
        `admin/user/${userId}/session`, data, { withCredentials: true }
    )

    return response.data
}

export const patchUserSessionById = async ( userId, sessionId, patchBody ) => {
    // patch an existing user session; patchBody must be an object containing session
    // fields and new values for those fields

    // REQUIRES AUTHENTICATION

    const response = await axios.patch(
        `admin/user/${userId}/session/${sessionId}`, patchBody,
        { withCredentials: true }
    )

    return response.data
}
