import { axios } from './index';


export const userInfo = async () => {
    // get info on currenlty logged user
    // DO NOT pass sensitive info in here

    // REQUIRES AUTHENTICATION

    const response = await axios.get(`user/info`, { withCredentials: true });

    return response.data;
}

export const getUserDocPresignedUrl = async ( sessionId, filename ) => {
    // get pre-signed URL to download a document belonging to the currently
    // logged in user, given the document s3 key

    // REQUIRES AUTHENTICATION

    const data = {
        'session_id': sessionId,
        'filename': filename
    }
    // get file from S3
    const response = await axios.post(
        `user/doc/download`, data, { withCredentials: true }
    );

    return response.data
}

export const getUserSessions = async () => {
    // get all sessions for logged in user

    // REQUIRES AUTHENTICATION

    const response = await axios.get(
        `/user/session/all`,
        { withCredentials: true }
    );

    return response.data;
}