import axios from 'axios';


//const cookiesConsent = localStorage.getItem('cookiesConsent');

const awsBaseUrl = 'https://quz5s21nzh.execute-api.eu-south-1.amazonaws.com/prod';

export var base_url; 
if (process.env.NODE_ENV === 'production') {
    base_url = 'https://compute.corivia.es:443'
} else {
    base_url = 'http://localhost:5000'
    //base_url = 'https://compute.corivia.es:443'
}
axios.defaults.baseURL = base_url;

// some functions use the AWS API
export const awsApi = axios.create({
    baseURL: awsBaseUrl,
});


export * from './email';
export * from './logs';
export * from './auth';
export * from './users';
export * from './admin';

export { axios };
