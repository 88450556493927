import React, { useEffect } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './index.scss';
import { HomePage } from './pages/HomePage';
import { PricesPage } from './pages/PricesPage';
import { AboutUsPage } from './pages/AboutUsPage';
import { ContactUsPage } from './pages/ContactUsPage';
import { BookingPage } from './pages/BookingPage';
import { LoginPage } from './pages/LoginPage';
import { SignupPage } from './pages/SignupPage';
import { DashboardPage } from './pages/DashboardPage';
import { CompaniesPage } from './pages/CompaniesPage';
import { postLog } from './services';
import { CookiesDisclaimer } from './components/CookiesDisclaimer';
import { useCookiesConsentStore } from './store';
import { ProtectedRoutes } from './components/ProtectedRoutes';


var visitedScrollPercentages = [];

// check current scroll percentgae depth with respect to page depth
const computeScrollPercentage = () => {
    var h = document.documentElement, 
    b = document.body,
    st = 'scrollTop',
    sh = 'scrollHeight';

    var scrollPercentage = (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight);

    return Math.round(scrollPercentage * 100)/100
}

// push log if user reaches certain scroll depths
const onScroll = () => {
    const alarmThresholds = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
    
    var currentScrollPercentage = computeScrollPercentage();
    visitedScrollPercentages.push(currentScrollPercentage);

    if (
        alarmThresholds.includes(visitedScrollPercentages.at(-1)) &&
        visitedScrollPercentages.at(-1) !== visitedScrollPercentages.at(-2)
    ) {
        postLog(
            'scroll', 'depth', `${currentScrollPercentage * 100}%`
        )
    }
}

window.addEventListener("scroll", onScroll);


const AppRouter = () => {

	return <Routes>
		<Route path='/:lang?' element={<HomePage />}/>
		<Route path='/:lang?/prices' element={<PricesPage />}/>
		<Route path='/:lang?/about-us' element={<AboutUsPage />}/>
		<Route path='/:lang?/contact-us' element={<ContactUsPage />}/>
		<Route path='/:lang?/booking' element={<BookingPage />}/>
        <Route path='/:lang?/companies' element={<CompaniesPage />}/>
        <Route path='/:lang?/login' element={<LoginPage />}/>
        <Route path='/:lang?/signup' element={<SignupPage />}/>
        <Route element={<ProtectedRoutes />} >
            <Route path='/dashboard' element={<DashboardPage />} />
        </Route>
	</Routes>
}


const App = () => {

    const { i18n } = useTranslation();
    const cookiesConsent = useCookiesConsentStore(state => state.cookiesConsent);

    const availableLanguages = ['en', 'it'];

    // if language parameter is present in the url, change app language
    useEffect(() => {
        // .at(3) because the third slash is the one that matters here, keep in mind
        // that the first two slashs are the ons after http or https
        const language = window.location.href.split('/').at(3);
        if ( availableLanguages.includes(language) ) {
            i18n.changeLanguage(language);
        }
    }, [])

	return <BrowserRouter>
        {
            cookiesConsent === null && <CookiesDisclaimer />
        }
		<AppRouter />
	</BrowserRouter>
}

export default App;
