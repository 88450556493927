import React from 'react';
import { Trans } from 'react-i18next';

import './index.scss';
import silvia from '../../assets/silvia.png';


export const AboutUsSection = () => {
    return <div className='row  mx-0 about-us-section'>
        <div className='row p-0 m-0 flex-column-reverse flex-md-row'>
            <div className='col-lg-8 col-md-12 pt-5 pe-lg-5'>
                <p>
                    <Trans 
                        i18nKey='aboutUsPage.aboutUsSection.firstParagraph'
                        components={{
                            bold: <b />
                        }}
                    />
                </p>
                <p className='mt-5'>
                    <Trans 
                        i18nKey='aboutUsPage.aboutUsSection.secondParagraph'
                        components={{
                            bold: <b />
                        }}
                    />
                </p>
            </div>
            <div className='col-lg-4 col-md-12 pt-5'>
                <img src={silvia} alt='lezioni di Spagnolo con Silvia' width='100%' />
            </div>
        </div>
        <div className='row'>
            <div className='highlighted-paragraph mt-5'>
                <p className='mt-3'>
                    <Trans 
                        i18nKey='aboutUsPage.aboutUsSection.thirdParagraph'
                        components={{
                            bold: <b />
                        }}
                    />
                </p>
            </div>
            <p className='mt-5'>
                <Trans 
                    i18nKey='aboutUsPage.aboutUsSection.fourthParagraph'
                    components={{
                        bold: <b />
                    }}
                />
            </p>
        </div>
    </div>
}

export default AboutUsSection;