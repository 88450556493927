import React, { useState } from 'react';

import './index.scss';
import sample_avatar from '../../assets/sample_avatar.png';
import { ProfileOptionsBox } from '../../components/ProfileOptionsBox';


export const DashboardNavbar = () => {

    const [ profileOptionsBoxOpen, setProfileOptionsBoxOpen ] = useState(false);
    
    return <nav className='dashboard-navbar'>
        <span className='dashboard-navbar-brand'>
            Corivia
        </span>
        <div className='m-0 p-0 float-end'>
            <img 
                src={sample_avatar} 
                alt='avatar'
                height='35rem'
                className='dashboard-navbar-avatar mt-1'
                onClick={() => setProfileOptionsBoxOpen(!profileOptionsBoxOpen)}
            />
        </div>
        { profileOptionsBoxOpen && <ProfileOptionsBox /> }
    </nav>
}

export default DashboardNavbar;