import React from 'react';
import { Trans } from 'react-i18next';

import './index.scss';
import { ColoredButton } from '../../components/ColoredButton';


export const PricingCard = ({ titleI18nKey, priceI18nKey, detailI18nKey, handleBookNowClick }) => {
    return <div className='pricing-card'>
        <p className='card-title'>
            <Trans 
                i18nKey={titleI18nKey}
            />
        </p>
        <hr />
        <p className='card-price'>
            <Trans 
                i18nKey={priceI18nKey}
            />
        </p>
        <p className='card-detail'>
            <Trans 
                i18nKey={detailI18nKey}
            />
        </p>
        <ColoredButton
            type='primary'
            onClick={handleBookNowClick}
        >
            <Trans 
                i18nKey='pricesPage.pricesSection.bookNowCTAButton'
            />
        </ColoredButton>
    </div>
}

export default PricingCard;