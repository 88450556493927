import React, { useEffect } from 'react';

import './index.scss';
import { HeroSection } from '../../layouts/HeroSection';
import { Navbar } from '../../layouts/Navbar';
import { CoursesSection } from '../../layouts/CoursesSection';
import { IntroSection } from '../../layouts/IntroSection';
import { WhyUsSection } from '../../layouts/WhyUsSection';
import { Footer } from '../../layouts/Footer';
import { CoursesDescriptionSection } from '../../layouts/CoursesDescriptionSection';


export const HomePage = () => {

	useEffect(() => {
		window.scrollTo(0, 0);
	})

	return <div>
		<Navbar />
		<HeroSection />
		<div className='primary-background'>
			<IntroSection />
			<CoursesSection />
			<WhyUsSection />
			<CoursesDescriptionSection />
		</div>
		<Footer />
	</div>
}

export default HomePage;
