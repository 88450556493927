import React from 'react';
import { Trans } from 'react-i18next';

import './index.scss';
import { ColoredButton } from '../../components/ColoredButton';


export const CourseCard = (
    { image, i18nKey, handleLearnMoreClick, handleBookNowClick }
) => {
    return <div className='course-card'>
        <img src={image} alt='spanish courses' width='90%' />
        <h4 className='mt-5'>
            <Trans 
                i18nKey={i18nKey}
            />
        </h4>
        <div className='row mt-5'>
            <div className='col-6'>
                <ColoredButton
                    onClick={handleLearnMoreClick}
                >
                    <Trans 
                        i18nKey='coursesPage.coursesSection.learnMoreCTA'
                    />
                </ColoredButton>
            </div>
            <div className='col-6'>
                <ColoredButton
                    onClick={handleBookNowClick}
                >
                    <Trans 
                        i18nKey='coursesPage.coursesSection.bookNowCTA'
                    />
                </ColoredButton>
            </div>
        </div>
    </div>
}

export default CourseCard;