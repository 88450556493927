import React from 'react';

import { SignupForm } from '../../components/SignupForm';
import './index.scss';


export const SignupPage = () => {
    return <div className='signup-page'>
        <SignupForm />
    </div>
}

export default SignupPage;