import React from 'react';
import { Trans } from 'react-i18next';

import './index.scss';


export const SmallHeroSection = ({titleI18nKey, subtitleI18nKey }) => {
    return <div className='small-hero-section'>
        <div className='jumbotron jumbotron-fluid text-center'>
            <div className='container'>
                <p className='small-hero-title'>
                    <Trans 
                        i18nKey={titleI18nKey}
                    />
                </p>
                <p className='small-hero-subtitle mt-4'>
                    <Trans 
                        i18nKey={subtitleI18nKey}
                        components={{
                            bold: <b />
                        }}
                    />
                </p>
            </div>
        </div>
    </div>
}

export default SmallHeroSection;