import React from 'react';
import { Trans } from 'react-i18next';

import './index.scss'
//import { useNavigate } from 'react-router-dom';
import { postLog } from '../../services';


export const Footer = () => {

    const coriviaInstagramUrl = 'https://instagram.com/corivia.es?igshid=OGQ5ZDc2ODk2ZA=='
    //const navigate = useNavigate()

    return <div className='row m-0 footer p-4 p-md-5'>
        <div className='row mb-5'>
            <span className='footer-logo'>Corivia</span>
        </div>
        <div className='row footer-titles-row'>
            <div className='col-4 col-md-2'>
                <p>
                    <Trans 
                        i18nKey='footer.firstColumn.title'
                    />
                </p>
            </div>
            <div className='col-4 col-md-2'>
                <p>
                    <Trans 
                        i18nKey='footer.secondColumn.title'
                    />
                </p>
            </div>
            <div className='col-4 col-md-2'>
                <p>
                    <Trans 
                        i18nKey='footer.thirdColumn.title'
                    />
                </p>
            </div>
        </div>
        <div className='row mt-3 mb-4 footer-links-row'>
            <div className='col-4 col-md-2'>
                <p
                    onClick = {() => {
                        postLog('click', 'footer_link', 'home');
                        //navigate('/');
                        window.open('/', '_self');
                    }}
                >
                    <Trans 
                        i18nKey='footer.firstColumn.firstRow'
                    />
                </p>
                <p
                    onClick = {() => {
                        postLog('click', 'footer_link', 'prices');
                        //navigate('/prices');
                        window.open('/prices', '_self');
                    }}
                >
                    <Trans 
                        i18nKey='footer.firstColumn.secondRow'
                    />
                </p>
                <p
                    onClick = {() => {
                        postLog('click', 'footer_link', 'about_us');
                        //navigate('/about-us');
                        window.open('/about-us', '_self');
                    }}
                >
                    <Trans 
                        i18nKey='footer.firstColumn.thirdRow'
                    />
                </p>
                <p
                    onClick = {() => {
                        postLog('click', 'footer_link', 'contact_us');
                        //navigate('/contact-us');
                        window.open('/contact-us', '_self');
                    }}
                >
                    <Trans 
                        i18nKey='footer.firstColumn.fourthRow'
                    />
                </p>
            </div>
            <div className='col-4 col-md-2'>
                <p
                    onClick = {() => {
                        postLog('click', 'footer_link', 'private_classes');
                        //navigate('/prices?scrollTo=adults-children-classes')
                        window.open('/prices?scrollTo=adults-children-classes', '_self');
                    }}
                >
                    <Trans 
                        i18nKey='footer.secondColumn.firstRow'
                    />
                </p>
                <p
                    onClick = {() => {
                        postLog('click', 'footer_link', 'conversation_classes');
                        window.open('/prices?scrollTo=conversation-classes', '_self');
                    }}
                >
                    <Trans 
                        i18nKey='footer.secondColumn.secondRow'
                    />
                </p>
                <p
                    onClick = {() => {
                        postLog('click', 'footer_link', 'children_classes');
                        window.open('/prices?scrollTo=adults-children-classes', '_self');
                    }}
                >
                    <Trans 
                        i18nKey='footer.secondColumn.thirdRow'
                    />
                </p>
                <p
                    onClick={() => {
                        postLog('click', 'footer_link', 'corporate_classes');
                        window.open('/companies', '_self');
                    }}
                >
                    <Trans 
                        i18nKey='footer.secondColumn.fourthRow'
                    />
                </p>
            </div>
            <div className='col-4 col-md-2'>
                <p onClick={() => window.open(coriviaInstagramUrl, '_blank')}>
                    <Trans 
                        i18nKey='footer.thirdColumn.firstRow'
                    />
                </p>
                <p onClick={() => window.open(coriviaInstagramUrl, '_blank')}>
                    <Trans 
                        i18nKey='footer.thirdColumn.secondRow'
                    />
                </p>
                <p onClick={() => window.open(coriviaInstagramUrl, '_blank')}>
                    <Trans 
                        i18nKey='footer.thirdColumn.thirdRow'
                    />
                </p>
                <p onClick={() => window.open(coriviaInstagramUrl, '_blank')}>
                    <Trans 
                        i18nKey='footer.thirdColumn.fourthRow'
                    />
                </p>
            </div>
        </div>
        <hr />
        <div className='row mt-4'>
            <p className='mb-0'>
                <Trans 
                    i18nKey='footer.copyrightText'
                />
            </p>
        </div>
    </div>
};

export default Footer;
