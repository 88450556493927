import React, { useState } from 'react';
//import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';

import './index.scss';
import { userSignup, userLogin } from '../../services';


export const SignupForm = () => {

    const { t } = useTranslation();
    //const navigate = useNavigate();

    const initialFormData = {
        emailAddress: '',
        password: '',
        firstName: '',
        lastName: ''
    }
    const initialValidationErrors = {
        emailAddress: '',
        password: '',
        firstName: '',
        lastName: ''
    }

    const [ formData, setFormData ] = useState(initialFormData);
    const [ validationErrors, setValidationErrors ] = useState(
        initialValidationErrors
    );
    const [ signingUp, setSigningUp ] = useState(false);

    // set form data as user types
    const handleFormTyping = (event) => {
        const { name, value } = event.target;

        setFormData((prevData) => ({
            ...prevData,
            // dynamically set initialFormData object key
            [name]: value,
        }))
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        // reset any error that may have been thwrown on last submit
        let validationError = false;
        setValidationErrors(initialValidationErrors);

        // validate form by iterating over formData keys. 
        // Fields must not be null
        Object.keys(formData).forEach(
            (key) => {
                if (formData[key] === '') {
                    setValidationErrors((prevState) => ({
                        ...prevState,
                        [key]: 'nullValue'
                    }));
                    validationError = true;
                }
            }
        )

        if (!validationError) {
            try {
                setSigningUp(true);
                const response = await userSignup(
                    formData['emailAddress'], formData['password'],
                    formData['firstName'], formData['lastName']
                );

                if (response.code === 200) {
                    // log in newly created user and redirect to dashboard
                    await userLogin(formData['emailAddress'], formData['password']);
                    //navigate('/dashboard');
                    window.open('/dashboard', '_self');
                }
                setSigningUp(false);
            } catch (error) {
                // user already exists, set validation error
                if (error.response.status === 409) {
                    setValidationErrors((prevState) => ({
                        ...prevState,
                        emailAddress: 'alreadyExists'
                    }));
                }
                setSigningUp(false);
            }    
        }

        // reset form
        //setFormData(initialFormData)
    }

    return <div className='signup-form-container'>
        <h3>
            <Trans 
                i18nKey={'signupPage.title'}
            />
        </h3>
        <p className='mt-4'>
            <Trans 
                i18nKey={'signupPage.subtitle'}
            />
        </p>
        <form onSubmit={handleFormSubmit}>
            {/* Email address */}
            <div className='form-group mt-4'>
                <div className='row'>
                    <div className='col-4'>
                        <label htmlFor='inputEmail'>
                            <Trans 
                                i18nKey={'signupPage.emailBox.title'}
                            />
                        </label>
                    </div>
                    <div className='col-8'>
                        {/* Email address input is empty */}
                        { validationErrors['emailAddress'] === 'nullValue' && 
                            <div className='error-message'>
                                <Trans 
                                    i18nKey={'signupPage.emailBox.noEmailError'}
                                />
                            </div> }
                        {/* Email address input is empty */}
                        { validationErrors['emailAddress'] === 'alreadyExists' && 
                            <div className='error-message'>
                                <Trans 
                                    i18nKey={'signupPage.emailBox.duplicateEmailError'}
                                />
                            </div> }
                    </div>
                </div>
                <input
                    type='email'
                    name='emailAddress'
                    className='form-control' 
                    placeholder={
                        t('signupPage.emailBox.placeholder')
                    }
                    value={formData.emailAddress}
                    onChange={handleFormTyping}
                />
            </div>
            {/* Password */}
            <div className='form-group mt-4'>
                <div className='row'>
                    <div className='col-4'>
                        <label>
                            <Trans 
                                i18nKey={'signupPage.passwordBox.title'}
                            />
                        </label>
                    </div>
                    <div className='col-8'>
                        {/* Password input is empty */}
                        { validationErrors['password'] === 'nullValue' && 
                            <div className='error-message'>
                                <Trans 
                                    i18nKey={'signupPage.passwordBox.noPasswordError'}
                                />
                            </div> }
                    </div>
                </div>
                <input 
                    type='password' 
                    name='password'
                    className='form-control' 
                    placeholder={
                        t('signupPage.passwordBox.placeholder')
                    }
                    value={formData.password}
                    onChange={handleFormTyping}
                />
            </div>
            {/* First name */}
            <div className='form-group mt-4'>
                <div className='row'>
                    <div className='col-4'>
                        <label>
                            <Trans 
                                i18nKey={'signupPage.firstNameBox.title'}
                            />
                        </label>
                    </div>
                    <div className='col-8'>
                        {/* First name input is empty */}
                        { validationErrors['firstName'] === 'nullValue' && 
                            <div className='error-message'>
                                <Trans 
                                    i18nKey={'signupPage.firstNameBox.noFirstNameError'}
                                />
                            </div> }
                    </div>
                </div>
                <input 
                    type='text' 
                    name='firstName'
                    className='form-control' 
                    placeholder={
                        t('signupPage.firstNameBox.placeholder')
                    }
                    value={formData.firstName}
                    onChange={handleFormTyping}
                />
            </div>
            {/* Last name */}
            <div className='form-group mt-4'>
                <div className='row'>
                    <div className='col-4'>
                        <label>
                            <Trans 
                                i18nKey={'signupPage.lastNameBox.title'}
                            />
                        </label>
                    </div>
                    <div className='col-8'>
                        {/* Password input is empty */}
                        { validationErrors['lastName'] === 'nullValue' && 
                            <div className='error-message'>
                                <Trans 
                                    i18nKey={'signupPage.lastNameBox.noLastNameError'}
                                />
                            </div> }
                    </div>
                </div>
                <input 
                    type='text' 
                    name='lastName'
                    className='form-control' 
                    placeholder={
                        t('signupPage.lastNameBox.placeholder')
                    }
                    value={formData.lastName}
                    onChange={handleFormTyping}
                />
            </div>
            <button type='submit' className='btn sign-up-button mt-5'>
                <Trans 
                    i18nKey={'signupPage.signupButton'}
                />
                <ClipLoader
                    color={'white'}
                    loading={signingUp}
                    size={15}
                    className='ms-3'
                />
            </button>
        </form>
        <p className='mt-4 text-center'>
            <Trans 
                i18nKey={'signupPage.redirectToLogin'}
                components={{
                    linkToLogin: <a 
                        href='/login' 
                    />
                }}
            />
        </p>
    </div>
}

export default SignupForm;