import React from 'react';

import './index.scss';
import { useSystemStore } from '../../store';
import { userLogout } from '../../services';
//import { useNavigate } from 'react-router-dom';


export const ProfileOptionsBox = () => {

    const userInfo = useSystemStore(state => state.userInfo);
    const setUserRole = useSystemStore(state => state.setUserRole);
    const deleteUserInfo = useSystemStore(state => state.deleteUserInfo);
    //const navigate = useNavigate()

    const handleLogoutClick = () => {
        userLogout();
        // clear user role and user
        setUserRole(null);
        deleteUserInfo();

        //navigate('/login');
        window.open('/login', '_self');
    }

    return <div className='profile-options-box'>
        <div className='row m-0 p-0'>
            <span>{userInfo.emailAddress}</span>
        </div>
        <hr />
        <div className='row m-0 mt-3 p-0'>
            <span 
                className='profile-options-box-link'
                onClick={handleLogoutClick}
            >
                Logout
            </span>
        </div>        
    </div>
}