import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import './index.scss';
import logo from '../../assets/logo.png'
import { postLog } from '../../services';
import united_kingdom from '../../assets/united_kingdom.png';
import italy from '../../assets/italy.png';


const NavbarToggle = ({ onClick }) => {
    return (
        <i className="bi bi-list navbar-toggle-icon" onClick={onClick}></i>
    );
};


export const NavbarCollapse = ({ isOpen }) => {

    return <div className={`navbar-collapse text-start ${isOpen && 'menu-active'}`}>
        <ul className='navbar-nav'>
            <li className='nav-item'>
                <a 
                    className='nav-link'
                    onClick={(e) => {
                        e.preventDefault();
                        postLog('click', 'navbar_link', 'home');
                        window.open('/', '_self');
                    }}
                >
                    <Trans 
                        i18nKey="navbar.spanishClasses"
                    />
                </a>
            </li>
            <li className='nav-item'>
                <a 
                    className='nav-link'
                    onClick={(e) => {
                        e.preventDefault();
                        postLog('click', 'navbar_link', 'companies');
                        window.open('/companies', '_self');
                    }}
                >
                    <Trans 
                        i18nKey="navbar.companies"
                    />
                </a>
            </li>
            <li className='nav-item'>
                <a 
                    className='nav-link'
                    onClick={(e) => {
                        e.preventDefault();
                        postLog('click', 'navbar_link', 'prices');
                        window.open('/prices', '_self');
                    }}
                >
                    <Trans 
                        i18nKey="navbar.prices"
                    />
                </a>
            </li>
            <li className='nav-item'>
                <a 
                    className='nav-link'
                    onClick={(e) => {
                        e.preventDefault();
                        postLog('click', 'navbar_link', 'about_us');
                        window.open('/about-us', '_self');
                    }}
                >
                    <Trans 
                        i18nKey="navbar.aboutUs"
                    />
                </a>
            </li>
            <li className='nav-item'>
                <a 
                    className='nav-link'
                    onClick={(e) => {
                        e.preventDefault();
                        postLog('click', 'navbar_link', 'contact_us');
                        window.open('/contact-us', '_self');
                    }}
                >
                    <Trans 
                        i18nKey="navbar.contacts"
                    />
                </a>
            </li>
            <li className='nav-item'>
                <a 
                    className='nav-link'
                    onClick={(e) => {
                        e.preventDefault();
                        postLog('click', 'navbar_link', 'login');
                        window.open('/dashboard', '_self');
                    }}
                >
                    <Trans 
                        i18nKey="navbar.login"
                    />
                </a>
            </li>
        </ul>
    </div>
};


export const Navbar = () => {

    //const navigate = useNavigate();
    const { i18n } = useTranslation();

    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const handleChangeLanguage = ( languageCode ) => {
        i18n.changeLanguage(languageCode);
    }

    return <div className='navbar row text-center m-0'>
        {/* Large devices */}
        <div className='col-2 m-0 p-0 desktop-only'>
            <div className='logo-container desktop-only'>
                <img 
                    src={logo} 
                    alt='logo' 
                    className='logo' 
                    onClick={()=> window.open('/', '_self')}
                />
            </div>
        </div>
        <div className='col-1 m-0 p-0 desktop-only'>
            <span onClick={() => {
                postLog('click', 'navbar_link', 'home');
                window.open('/', '_self');
            }}>
                <Trans 
                    i18nKey="navbar.spanishClasses"
                />
            </span>
        </div>
        <div className='col-2 m-0 p-0 desktop-only'>
            <span onClick={() => {
                postLog('click', 'navbar_link', 'companies');
                window.open('/companies', '_self');
            }}>
                <Trans 
                    i18nKey="navbar.companies"
                />
            </span>
        </div>
        <div className='col-1 m-0 p-0 desktop-only'>
            <span onClick={() => {
                postLog('click', 'navbar_link', 'prices');
                //navigate('/prices');
                window.open('/prices', '_self');
            }}>
                <Trans 
                    i18nKey="navbar.prices"
                />
            </span>
        </div>
        <div className='col-2 m-0 p-0 desktop-only'>
            <span onClick={() => {
                postLog('click', 'navbar_link', 'about_us');
                //navigate('/about-us');
                window.open('/about-us', '_self');
            }}>
                <Trans 
                    i18nKey="navbar.aboutUs"
                />
            </span>
        </div>
        <div className='col-1 m-0 p-0 desktop-only'>
            <span onClick={() => {
                postLog('click', 'navbar_link', 'contact_us');
                //navigate('/contact-us');
                window.open('/contact-us', '_self');
            }}>
                <Trans 
                    i18nKey="navbar.contacts"
                />
            </span>
        </div>
        <div className='col-2 m-0 p-0 desktop-only position-relative'>
            <div className='row language-selection-container'>
                <div className='col-6 text-end'>
                    <img 
                        src={united_kingdom} 
                        width='50%' 
                        alt='english' 
                        onClick={() => handleChangeLanguage('en')}
                    />
                </div>
                <div className='col-6 text-start'>
                    <img 
                        src={italy} 
                        width='50%' 
                        alt='italian'
                        onClick={() => handleChangeLanguage('it')}
                    />
                </div>
            </div>
            <div className='row'>
                <span onClick={() => {
                    postLog('click', 'navbar_link', 'logi');
                    //navigate('/dashboard');
                    window.open('/dashboard', '_self');
                }}>
                    <Trans 
                        i18nKey="navbar.login"
                    />
                </span>
            </div>
        </div>
    
        {/* Small devices */}
        <div className='row'>
            <div className='col-4 text-start mobile-only'>
                <img 
                    src={logo} 
                    alt="logo" 
                    className='logo mobile-only' 
                    onClick={() => window.open('/', '_self')}
                />
            </div>
            <div className='col-4 mobile-only'>
                <div className='row'>
                    <div className='col-6'>
                        <img 
                            src={united_kingdom} 
                            alt='english' 
                            className='mt-3 language-selection-icon' 
                            onClick={() => handleChangeLanguage('en')}
                        />
                    </div>
                    <div className='col-6'>
                        <img 
                            src={italy} 
                            alt='italian' 
                            className='mt-3 language-selection-icon' 
                            onClick={() => handleChangeLanguage('it')}
                        />
                    </div>
                </div>
            </div>
            <div className='col-4 mobile-only m-0 px-0 pt-2'>
                <NavbarToggle onClick={toggleMenu} />
            </div>
        </div>
        <NavbarCollapse isOpen={isMenuOpen} />
    </div>
}

export default Navbar;