import React, { useState, useEffect, useRef } from 'react';
import { Trans } from 'react-i18next';

import './index.scss';
import { getUserDocPresignedUrl } from '../../services';


export const UserSessionsTable = ({ userSessions }) => {

    const [ documentDownloadLink, setDocumentDownloadLink ] = useState(null);
    const [ documentFilename, setDocumentFilename ] = useState(null);
    
    const containerRef = useRef(null);

    const tableRows = [
        'session_date', 'session_content', 'homework_filename', 'session_notes'
    ]

    // logic to download file from session row
    const handleFileDownload = async ( sessionId, filename ) => {
        setDocumentFilename(filename);
        // get presigned url to initiate file download; endpoint that returns
        // the presigned url is different based on user role
        const presignedUrlData = await getUserDocPresignedUrl(sessionId, filename);
        // once documentDownloadLink is set, download will start automatically
        setDocumentDownloadLink(presignedUrlData.url);
    }

    useEffect(() => {
        // Initiate download automatically when documentDownloadLink is set
        const initiateDownload = () => {
            if (documentDownloadLink) {
                const anchor = document.createElement('a');
                anchor.href = documentDownloadLink;
                anchor.download = documentFilename;
                anchor.style.display = 'none';
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
            }
        };
    
        initiateDownload();
      }, [documentDownloadLink]);

    return <table className='table' ref={containerRef}>
        <thead>
            <tr>
                <th scope='column'>
                    <Trans 
                        i18nKey={
                            `dashboardPage.userSessionsTable.columnsHeading.firstColumn`
                        }
                    />
                </th>
                <th scope='column'>
                    <Trans 
                        i18nKey={
                            `dashboardPage.userSessionsTable.columnsHeading.secondColumn`
                        }
                    />
                </th>
                <th scope='column'>
                    <Trans 
                        i18nKey={
                            `dashboardPage.userSessionsTable.columnsHeading.thirdColumn`
                        }
                    />
                </th>
                <th scope='col'>
                    <Trans 
                        i18nKey={
                            `dashboardPage.userSessionsTable.columnsHeading.fourthColumn`
                        }
                    />
                </th>
                <th scope='col'>
                    <Trans 
                        i18nKey={
                            `dashboardPage.userSessionsTable.columnsHeading.fifthColumn`
                        }
                    />
                </th>
            </tr>
        </thead>
        <tbody>
            { userSessions.map((session, index) => (
                <tr key={index}>
                    <th scope='row'>{session.session_id}</th>
                    { tableRows.map((key, index) => {
                        if (key === 'homework_filename') {
                            // homework filename must be a link to download 
                            // the file
                            return <td 
                                key={index}
                                className='homework-name'
                                onClick={
                                    () => handleFileDownload(
                                        session['session_id'],
                                        session['homework_filename']
                                    )
                                }
                            >
                                {session[key]}
                            </td>
                        } else {
                            // all other keys should be displayed normally
                            return <td key={index}>{session[key]}</td>
                        }
                    }) }
                </tr>
            )) }
        </tbody>
    </table>
}

export default UserSessionsTable;