import React from 'react';
import { Trans } from 'react-i18next';

import './index.scss';


export const BoxWithCTAButton = ({ children, i18nKey, handleCTAButtonClick }) => {
    return <div className='row price-card-group'>
        <div>
            {children}
        </div>
        <div>
            <button
                type='button' 
                className='btn cta-button'
                onClick={handleCTAButtonClick}
            >
                <Trans 
                    i18nKey={i18nKey}
                />
            </button>
        </div>
    </div>
}

export default BoxWithCTAButton;