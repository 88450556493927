import { create } from 'zustand';


const initialCookiesConsent = localStorage.getItem('cookiesConsent');
const initialUserInfo = {
    emailAddress: null,
    firstName: null,
    lastName: null,
    meetingLink: null,
}


export const useCookiesConsentStore = create((set) => ({
    cookiesConsent: initialCookiesConsent,

    setCookiesConsent: (bool) => set(() => ({
        cookiesConsent: bool
    }))
}));

export const useSystemStore = create((set) => ({
    userInfo: initialUserInfo,
    userRole: null,
    listOfStudents: null,
    listOfSessions: null,

    setUserInfo: (emailAddress, firstName, lastName, meetingLink) => set(() => ({
        userInfo: {
            emailAddress: emailAddress,
            firstName: firstName,
            lastName: lastName,
            meetingLink: meetingLink,
        }
    })),
    setUserRole: (role) => set(() => ({
        userRole: role
    })),
    deleteUserInfo: () => set(() => ({
        userInfo: initialUserInfo
    })),
    setListOfStudents: (listOfStudents) => set(() => ({
        listOfStudents: listOfStudents
    })),
    setListOfSessions: (listOfSessions) => set(() => ({
        listOfSessions: listOfSessions
    }))
}));

export const useAdminStore = create((set) => ({
    editableUserSessions: [],
    userSessionFormData: {
        session_id: '',
        session_date: '', 
        session_content: '',
        homework_filename: '',
        session_notes: ''
    },
    userSessionFile: null,

    addNewUserSession: () => set((state) => ({
        editableUserSessions: [...state.editableUserSessions, {
            user_id: '',
            session_id: '',
            session_date: '', 
            session_content: '',
            homework_filename: '',
            session_notes: ''
        }]
    })),
    deleteAllEditableUserSessions: () => set(() => ({
        editableUserSessions: []
    })),
    setUserSessionFormData: (newFormData) => set(() => ({
        userSessionFormData: newFormData
    })),
    resetUserSessionFormData: () => set(() => ({
        userSessionFormData: {
            session_id: '',
            session_date: '', 
            session_content: '',
            homework_filename: '',
            session_notes: ''
        },
    })),
    setUserSessionFile: (file) => set(() => ({
        userSessionFile: file
    }))
}));
